@use '../config/' as *;
.footer {
    &-wrap {
        @extend %white-bg;
    }
    &-middle-wrap,
    &-bottom-wrap {
        @extend %flex-c-c;
    }
    &-top-wrap {
        isolation: isolate;
        overflow: hidden;
        @extend %relative;
        @extend %secondary-dark-bg;
        @extend %px-3;
        @extend %py-8;
        &::after {
            content: "";
            width: calc(.69 * var(--window-inner-width));
            aspect-ratio: 1.15;
            z-index: var(--zindex-negative);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            filter: invert(15%) sepia(66%) saturate(2669%) hue-rotate(220deg) brightness(95%) contrast(88%);
            opacity: .3;
            pointer-events: none;
            rotate: 60deg;
            @include bgImg("svg/hexagon.svg");
            @include position(null, null, -18%, 60%)
        }
        .footer-nav-anchor {
            @extend %font-12;
        }
        .footer-link {
            @extend %my-2;
        }
    }
    &-middle-wrap {
        height: var(--footer-top-height);
        @extend %secondary-light-bg;
        .footer-nav-anchor {
            @extend %white;
            @extend %font-12-pr;
        }
        .footer-link-list {
            @extend %flex-wrap;
            @extend %flex-c-n;
        }
        .footer-link {
            @extend %relative;
            @extend %px-2;
            &:nth-child(2) {
                &::after {
                    display: none;
                }
            }
            &:not(:last-child) {
                &::after {
                    content: '';
                    width: 0.2rem;
                    height: 1.5rem;
                    right: 0;
                    @extend %white-bg;
                    @extend %position-y-center;
                }
            }
        }
    }
    &-bottom-wrap {
        height: var(--footer-bottom-height);
        @extend %accent-bg;
        .text {
            @extend %secondary-dark;
            @extend %font-12-pm;
        }
    }
    &-link-list {
        list-style: none;
        @extend %pl-0;
    }
    &-nav-anchor {
        width: max-content;
        @extend %capitalize;
        @extend %white-5;
        @extend %font-12-pr;
        @extend %flex-n-c;
    }
    &-link-label {
        @extend %uppercase;
        @extend %font-12-pb;
        @extend %white;
    }
    &-link-wrap {
        display: block;
        column-count: 2;
        @extend %d-block;
        .footer-link-item {
            break-inside: avoid-column;
            .footer-link {
                &:last-child {
                    @extend %mb-4;
                }
            }
        }
    }
    &-link-item-social {
        .footer {
            &-link-list {
                column-gap: var(--space-2);
                @extend %flex;
            }
            &-anchor {
                width: 2rem;
                height: 2rem;
                line-height: 1;
                @extend %font-12;
                @extend %circle-radius;
                @extend %flex-c-c;
                @extend %secondary-light;
                @extend %white-bg;
            }
            &-anchor-text {
                @extend %font-0;
            }
        }
    }
    &-link {
        @each $k, $v in $social {
            &-#{$k} {
                display: inline-block;
                margin-right: var(--space-2);
                .footer {
                    &-nav-text {
                        font-size: 0;
                    }
                    &-anchor {
                        width: 2rem;
                        height: 2rem;
                        line-height: 1;
                        @extend %font-12;
                        @extend %circle-radius;
                        @extend %flex-c-c;
                        @extend %secondary-light;
                        @extend %white-bg;
                    }
                }
            }
        }
    }
    &-clients {
        @extend %px-3;
        @extend %py-8;
    }
    .text {
        @extend %capitalize;
    }
}
.client {
    &-section {
        @extend %relative;
        &::after {
            content: '';
            height: .1rem;
            @extend %w-100;
            @extend %secondary-light-bg-1;
            @extend %position-b-l;
        }
    }
    &-head {
        @extend %my-2;
    }
    &-title {
        @extend %capitalize;
        @extend %text-center;
        @extend %secondary-light;
        @extend %font-12-pb;
    }
    &-section-list {
        @extend %flex-wrap;
        @extend %flex-c-c;
    }
    &-list {
        @extend %pb-4;
        @extend %relative;
        &.v-divider {
            &::after {
                content: '';
                width: .1rem;
                @extend %h-100;
                @extend %secondary-light-bg-1;
                @include position(null, 0, var(--space-3), null);
            }
        }
        &.divider {
            &::before {
                content: unset;
                width: .1rem;
                height: calc(100% + var(--space-2));
                @extend %secondary-light-bg-1;
                @extend %position-b-r;
            }
        }
    }
    &-list-wrap {
        column-gap: var(--space-3);
        row-gap: var(--space-5);
        @extend %gap-2;
        @extend %flex-wrap;
        @extend %flex-c-c;
    }
    &-img {
        width: auto;
        height: 3rem;
        &.bisleri,
        &.pokemon {
            height: 5rem;
        }
    }
    &-sub-title {
        @extend %pb-1;
        @extend %capitalize;
        @extend %secondary-light;
        @extend %font-12-pb;
        @extend %text-center;
    }
}
// changes as per level of footer client (if needed)
.client-one {
    .client-title {
        @extend %font-16;
    }
    &-img {
        height: 6rem
    }
}
.client-two {
    .client-title {
        @extend %font-14;
    }
    &-img {
        height: 5rem
    }
}
.client-three {
    .client-title {
        @extend %font-12;
    }
    &-img {
        height: 4rem
    }
}
.footer-clients-page {
    .client {
        &-title {
            @extend %uppercase;
        }
        &-list-wrap {
            @extend %gap-6;
        }
    }
}
.home {
    .footer-wrap {
        padding-bottom: calc(var(--scorestrip-toggle-height) + var(--quicklink-head-height));
    }
}
.quicklink-active {
    .footer-wrap {
        padding-bottom: var(--quicklink-height);
    }
}
@media screen and (min-width: $tablet-min-width) {
    .footer {
        &-middle-wrap {
            .footer-link {
                &:last-child {
                    padding-left: var(--space-2);
                }
                &:nth-child(2) {
                    &::after {
                        @include flex-config(flex);
                    }
                }
            }
        }
        &-top-wrap {
            padding-inline: 0;
            &::after {
                content: "";
                width: calc(.4 * var(--window-inner-width));
                top: 20%;
                left: 72%;
                bottom: unset;
            }
        }
        &-link-wrap {
            max-width: var(--container-max-width);
            margin-inline: auto;
            @include flex-config(flex);
        }
        &-link-item {
            flex: 1;
        }
        &-clients {
            max-width: var(--container-max-width);
            margin-inline: auto;
            padding: 6rem var(--space-0) 7.5rem;
        }
    }
    .client {
        &-section {
            &::after {
                content: unset;
            }
            &-list:not(:last-child) {
                margin-bottom: 7rem;
            }
        }
        &-primary-section-list {
            .client-title {
                margin-bottom: 2rem;
                font-size: 1.6rem;
            }
            .client-img {
                height: 5rem;
            }
        }
        &-title {
            text-transform: uppercase;
        }
        &-head {
            margin-bottom: var(--space-3);
        }
        &-list {
            padding-bottom: var(--space-0);
            &.divider {
                &::before {
                    content: '';
                }
            }
            &-wrap {
                gap: 4rem;
            }
        }
        &-img {
            height: 4rem;
            &.bisleri,
            &.pokemon {
                height: 8rem;
            }
            &.nivia {
                height: 7rem;
            }
        }
        &-section-list {
            align-items: flex-start;
            margin-inline: auto;
        }
    }
    .associate-client .client-title {
        font-size: 1.6rem;
    }
    .home {
        .footer-wrap {
            padding-bottom: var(--space-0);
        }
    }
    .quicklink-active {
        .footer-wrap {
            // padding-bottom: var(--quicklink-body-height);
            padding-bottom: 0;
        }
    }
}
@media (min-width: $desktop-min-width) {
    .footer {
        &-top-wrap {
            .footer-nav-anchor {
                font-size: 1.4rem;
            }
        }
        &-link-label {
            font-size: 1.4rem;
        }
    }
}