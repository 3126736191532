@use "../config" as *;
.waf-listing {
    .first-list {
        @extend %rhs-card-list;
        .article {
            &-item {
                &:nth-child(1) {
                    @include listing-card(card);
                    @extend %mx-3-neg;
                    &:hover {
                        .article-description {
                            height: auto;
                            opacity: 1;
                            @extend %mb-3;
                        }
                    }
                    &.item-type-article {
                        .article {
                            &-content {
                                &::before {
                                    content: unset;
                                }
                            }
                        }
                    }
                    .article-thumbnail {
                        .img-box {
                            &::after {
                                content: unset;
                            }
                            &::before {
                                content: "";
                                // background: linear-gradient(107.19deg, hsl(var(--club-primary), 0.8) -14.77%, hsl(var(--club-accent), 0.8) 100%);
                                background-image: linear-gradient(180deg, hsl(var(--hsl-secondary-dark)/0.7) 14.52%, hsl(var(--hsl-primary)/0.9) 104.88%);
                            }
                        }
                    }
                    .article-description {
                        height: 0;
                        opacity: 0;
                        @extend %transition;
                        @extend %mb-0;
                        @extend %d-block;
                    }
                    .article-wrap {
                        @extend %d-block;
                    }
                    .article-content {
                        width: calc(100% - 3rem);
                        margin-top: -8rem;
                        background-color: hsl(var(--hsl-secondary-dark) / 0.8);
                        box-shadow: 0 0.4rem 0.4rem 0 hsl(var(--hsl-black) / 0.25);
                        @include backdropBlur(4rem);
                        @extend %mx-auto;
                        @extend %half-radius;
                        .item-type-icon {
                            position: static;
                        }
                    }
                    .article-title {
                        @extend %white;
                    }
                    .article-meta {
                        @extend %white;
                    }
                }
            }
        }
    }
    .second-list {
        @extend %rhs-card-list;
    }
    .article-description {
        @extend %d-none;
    }
    .article-list {
        .waf-child-wrapper {
            margin-top: var(--space-0);
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-listing {
        .first-list {
            flex-direction: column;
            margin-top: var(--space-6);
            gap: var(--space-0);
            overflow: hidden;
            @include listing-card(overlay);
            .article {
                &-item {
                    width: 100%;
                    &:nth-child(1) {
                        margin-inline: 0;
                        .article-thumbnail {
                            .img-box {
                                &::after {
                                    content: "";
                                }
                            }
                        }
                        .article-content {
                            position: absolute;
                            margin: 0;
                            width: 100%;
                            padding-bottom: 0;
                            background-color: unset;
                            backdrop-filter: unset;
                            box-shadow: unset;
                        }
                        .article-title {
                            @include truncate(1, 26, 38);
                        }
                    }
                }
                &-content {
                    .item-type-icon {
                        &::before {
                            font-size: 3rem;
                        }
                    }
                }
            }
            .waf-child-wrapper {
                width: var(--container-max-width);
            }
        }
        .second-list {
            justify-content: flex-start;
            margin-top: var(--space-3);
            @include card-count(4, var(--space-3), wrap);
            @include listing-card(card);
            .article {
                &-wrap {
                    display: block;
                }
            }
        }
        .article-list {
            .waf-child-wrapper {
                margin-top: var(--space-6);
            }
        }
    }
}
@media screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
    .waf-listing {
        .second-list {
            @include card-count(3, var(--space-3), wrap);
        }
    }
}